import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useContext } from 'react';
import {Row, Col, Button, Input, Spin, Image} from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../config'
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

const { TextArea } = Input;

function LandingPageRight({CurrProjectId, HistoryList, update_item, onClickDelete, onClickCreateNext, get_history}) {
    const user = useSelector((state) => state.user);
    const token = user?.userData?.token;     // UserInfo={token, user_id}
    //const [HistoryList, setHistoryList] = useState(null);
    const [comment, setComment] = useState('');
    const [analysis, setAnalysis] = useState('');
    const [importance, setImportance] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editWhat, setEditWhat] = useState('');




    // comment
    const handleCommentClick = (index, currentComment) => {
        setEditIndex(index);
        setEditWhat('comment');
        setComment(currentComment);
        //setAnalysis(HistoryList[index].analysis);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    async function handleCommentBlur(index) {
        HistoryList[index].comment = comment;
        await update_item(HistoryList[index].path, {comment:comment});
        setEditIndex(null);
    };

    // analysis
    const handleAnalysisClick = (index, currentAnalysis) => {
        setEditIndex(index);
        setEditWhat('analysis');
        //setComment(HistoryList[index].comment);
        setAnalysis(currentAnalysis);
    };

    const handleAnalysisChange = (e) => {
        setAnalysis(e.target.value);
    };

    async function handleAnalysisBlur(index) {
        HistoryList[index].analysis = analysis;
        await update_item(HistoryList[index].path, {analysis:analysis});
        setEditIndex(null);
    };

    // importance
    const handleImportanceClick = (index, currentImportance) => {
        setEditIndex(index);
        setEditWhat('importance');
        //setImportance(HistoryList[index].importance);
        setImportance(currentImportance);
    };

    const handleImportanceChange = (e) => {
        setImportance(e.target.value);
    };

    async function handleImportanceBlur(index) {
        HistoryList[index].importance = importance;
        await update_item(HistoryList[index].path, {importance:importance});
        setEditIndex(null);
    };

    function onClickShowInference(imageUrl) {
        // Swal.fire({
        //     title: 'Here is your image',
        //     imageUrl: url,
        //     imageAlt: 'A description of the image',
        //     imageWidth: img.width,  // Setting the original width
        //     imageHeight: img.height // Setting the original height
        //     });

        // const img = new Image();
        // img.src = imageUrl;
        // img.onload = () => {
        //   Swal.fire({
        //     title: 'Here is your image',
        //     imageUrl: imageUrl,
        //     imageAlt: 'A description of the image',
        //     imageWidth: img.width,  // Setting the original width
        //     imageHeight: img.height // Setting the original height
        //   });
        // };

        const newWindow = window.open();
        newWindow.document.write(`
          <html>
            <head>
              <title>Image</title>
            </head>
            <body style="margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #f0f0f0;">
              <img src="${imageUrl}" alt="Image" style="max-width: 100%; max-height: 100%;" />
            </body>
          </html>
        `);
    }
    

    //////////////////////////////////// 일반적인 경우의 rendering//////////////////////////////////////////
    function render_item(hist, index){
        const path = hist.path;
        const jpg_simul_path = hist?.simul_jpg? `${USER_SERVER}/jpg/${hist.simul_jpg}`:'';
        const jpg_inference_path = hist?.inference_jpg? `${USER_SERVER}/jpg/${hist.inference_jpg}`:'';
        const isEditing = editIndex === index;
        //console.log('isEdit',  isEditing, editIndex, index, editWhat);
        console.log('jpg_inference_path', jpg_inference_path);
        return (
            <div key={index} style={{margin:'20px', border:'1px solid lightgray', padding:'10px'}}>
                <Row justify='start'>
                    <Col>
                        <b>{path}</b>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col>
                        {(isEditing && editWhat==='comment')? (
                            <TextArea
                                value={comment}
                                onChange={handleCommentChange}
                                onBlur={() => handleCommentBlur(index)}
                                onPressEnter={() => handleCommentBlur(index)} 
                                autoFocus
                                style={{width:'500px'}}
                            />
                        ) : (
                            <span onClick={() => handleCommentClick(index, hist.comment)} style={{pointer:'pointer'}}>
                                Comment: {hist.comment}
                            </span>
                        )}
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col>
                        {(isEditing && editWhat==='analysis') ? (
                            <TextArea
                                value={analysis}
                                onChange={handleAnalysisChange}
                                onBlur={() => handleAnalysisBlur(index)}
                                autoFocus
                                style={{width:'500px'}}
                            />
                        ) : (
                            <span onClick={() => handleAnalysisClick(index, hist.analysis)}>
                                Analysis: {hist.analysis}
                            </span>
                        )}
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col>
                        {(isEditing && editWhat==='importance') ? (
                            <Input
                                value={importance}
                                onChange={handleImportanceChange}
                                onBlur={() => handleImportanceBlur(index)}
                                autoFocus
                            />
                        ) : (
                            <span onClick={() => handleImportanceClick(index, hist.importance)}>
                                Importance: {hist.importance}
                            </span>
                        )}
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col>
                        <Image src= {jpg_simul_path} style={{width:'80%'}}/>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Col style={{margin:'10px'}}>
                        <Button disabled={jpg_inference_path===''} onClick={()=>onClickShowInference(jpg_inference_path)}>Show Inference</Button>
                    </Col>
                    <Col style={{margin:'10px'}}>
                        <Button type='primary' onClick={()=>{window.pick=path}}>Pick Item</Button>
                    </Col>
                    <Col style={{margin:'10px'}}>
                        <Button type="primary" onClick={()=>onClickDelete(path)}>Delete</Button>
                    </Col>
                    <Col style={{margin:'10px'}}>
                        <Button type="primary" onClick={()=>onClickCreateNext(path)}>CreateNext</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    //////////////////////////////////// comment의 경우 rendering//////////////////////////////////////////
    function render_comment_cell(hist, index){
        const path = hist.path;
        const isEditing = editIndex === index;
        //console.log('isEdit',  isEditing, editIndex, index, editWhat);
        return (
            <div key={index} style={{margin:'20px', border:'1px solid lightgray', padding:'10px'}}>
                <Row justify='start'>
                    <Col>
                        {(isEditing && editWhat==='comment')? (
                            <TextArea
                                value={comment}
                                onChange={handleCommentChange}
                                onBlur={() => handleCommentBlur(index)}
                                onPressEnter={() => handleCommentBlur(index)} 
                                autoFocus
                                style={{width:'500px', resize:'both'}}
                            />
                        ) : (
                            <span onClick={() => handleCommentClick(index, hist.comment)} style={{pointer:'pointer'}}>
                                {hist.comment}
                            </span>
                        )}
                    </Col>
                </Row>

                <Row justify='end'>
                    <Col style={{margin:'10px'}}>
                        <Button type='primary' onClick={()=>{window.pick=path}}>Pick Item</Button>
                    </Col>
                    <Col style={{margin:'10px'}}>
                        <Button type="primary" onClick={()=>onClickDelete(path)}>Delete</Button>
                    </Col>
                    <Col style={{margin:'10px'}}>
                        <Button type="primary" onClick={()=>onClickCreateNext(path)}>CreateNext</Button>
                    </Col>
                </Row>
            </div>
        );
    }


    function __render_hist(hist, index) {
        if ('importance' in hist)
            return render_item(hist, index);
        else
            return render_comment_cell(hist, index);
    }

    const render_histlist = HistoryList  && HistoryList.map(function(hist, index){
        //console.log(hist, hist?.importance);

        if (CurrProjectId==='all') {
            return __render_hist(hist, index);
        }
        else if (CurrProjectId==='unknown'){
            const project_id = hist?.project_id;
            if (!project_id || project_id==='unknown' ) {
                return __render_hist(hist, index);
            }
        }
        else {
            if (CurrProjectId===hist?.project_id) {
                return __render_hist(hist, index); 
            }
        }
    });

        
    return (
        <div>
            <Row>
                <Button type='primary' onClick={()=>get_history(true)}>Refresh</Button>
            </Row>

            <Row>
                <Col>
                    {render_histlist}
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(LandingPageRight)
