import React, { useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "../router/Routes";
import NavBar from "./views/00_NavBar/NavBar";

// todo: test 일반 사용자가 로그인사용자 메뉴에 접근할 수 없게 되었는지 검증
// todo: test 사용자가 관리자 메뉴에 접근할 수 없게 되었는지 검증

function App() {

    const pathName = window.location.pathname;

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <NavBar/>
                <Routes />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
