// set port of backend
const PORT                = 8000;     // for my private

const USER_SERVER   = '/api';
const SERVER_URL = 'http://127.0.0.1:' + PORT;

const REACT_APP_GOOGLE_CLIENT_ID="434999881954-00eph6u0rse4nrhsvndoepu7d6946fk7.apps.googleusercontent.com";

module.exports = {
    PORT,
    REACT_APP_GOOGLE_CLIENT_ID,
    USER_SERVER
}
