import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useContext } from 'react';
import {Row, Col, Button, Input, Spin, Image} from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../config'
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

const { TextArea } = Input;

function LandingPageLeft({ProjectList, addProjectList, CurrProjectId, setCurrProjectId, set_item_to_curr_project}) {
    const user = useSelector((state) => state.user);
    const [editIndex, setEditIndex] = useState(null);
    const [editWhat, setEditWhat] = useState('');


    const render_projectlist = ProjectList  && ProjectList.map(function(project, index){
        console.log('project:', project);
        const project_id = project._id;
        const bg_color = (project_id===CurrProjectId)? 'blue':'white';
        const fg_color = (project_id===CurrProjectId)? 'white':'black';
        return (
            <div key={index} onClick={()=>setCurrProjectId(project._id)} style={{margin:'20px', border:'1px solid lightgray', padding:'10px', backgroundColor:bg_color, color:fg_color}}>
                <Row justify='start'>
                    <Col>
                        {project.name}
                    </Col>
                </Row>
            </div>
        );}
    );

    async function onClickAddProject() {
        const { value: inputText } = await Swal.fire({
            title: 'Enter your text',
            input: 'text',
            inputPlaceholder: 'Type something...',
            showCancelButton: true,
        });

        if (inputText) {
            //setIsModalVisible(true);
            addProjectList(inputText);
        }

        
    }

    function onClickDropItem() {
        const item_path = window.pick;
        console.log('drop item', item_path);
        // const project_id = ProjectList[CurrProjectIndex]._id;
        set_item_to_curr_project(item_path);
    }

        
    return (
        <div>
            <Row>
                <h2>Projects:</h2>
            </Row>
            
            {render_projectlist}
            
            <Row justify='center' style={{margin:'10px'}}>
                <Button type='primary' onClick={onClickAddProject}>Add Project</Button>
            </Row>

            <Row justify='center' style={{margin:'10px'}}>
                <Button type='primary' onClick={onClickDropItem}>Drop Item</Button>
            </Row>

        </div>
    )
}

export default withRouter(LandingPageLeft)
