import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useContext } from 'react';
import {Row, Col, Button, Input, Modal, Image} from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../config'
import { useSelector } from "react-redux";
import LandingPageLeft from "./LandingPageLeft";
import LandingPageRight from "./LandingPageRight";


const { TextArea } = Input;

function LandingPage(props) {
    const user = useSelector((state) => state.user);
    const token = user?.userData?.token;     // UserInfo={token, user_id}
    const [HistoryList, setHistoryList] = useState(null);
    const [ProjectList, setProjectList] = useState([]);
    const [CurrProjectId, setCurrProjectId] = useState(null);


    useEffect(() => {
        get_history(false);
        get_project(false);
        
    }, [token]);

    async function get_history(refresh) {
        if (token) {
            const res = await axios.post(`${USER_SERVER}/get_history`, {token, refresh});
            console.log('get_history--------->', res);
            setHistoryList(res.data);
        }
    }

    async function get_project(refresh) {
        if (token) {
            const res = await axios.post(`${USER_SERVER}/get_project`, {token, refresh});
            console.log('get_project--------->', res);

            // project list 앞에 All, Unknown 을 추가함
            const project_list = [{_id:'all', name:'All'}, {_id:'unknown', name:'Unknown'}].concat(res.data);
            console.log('get_project--------->', project_list);
            setProjectList(project_list);

            if (!CurrProjectId) {
                setCurrProjectId(res.data[res.data.length-1]._id);
            }
        }
    }

    async function onClickDelete(item_path) {
        const res = await axios.post(`${USER_SERVER}/delete_item`, {token, path:item_path});
        console.log('delete_item--------->', res);
        await get_history(false);
    }

    async function onClickCreateNext(item_path) {
        try {
            const dataToCommit = {
                token:token,
                path: item_path + 'n',
                update: {comment:'new comment'}
            }
            const response = await axios.post(`${USER_SERVER}/upload_comment`, dataToCommit);
            console.log('Upload success:', response.data);
            await get_history();
        } catch (error) {
            console.error('Error uploading item:', error);
        }
    }

    async function update_item(path, update) {
        const res = await axios.post(`${USER_SERVER}/update_item`, {token, path, update});
        console.log('update_item--------->', res);
    }

    // async function onClickCreateNext(item_path) {
    //     try {
    //         const dataToCommit = {
    //             token:token,
    //             path: item_path + 'n',
    //             update: {comment:'new comment'}
    //         }
    //         const response = await axios.post(`${USER_SERVER}/upload_comment`, dataToCommit);
    //         console.log('Upload success:', response.data);
    //         await get_history();
    //     } catch (error) {
    //         console.error('Error uploading item:', error);
    //     }
    // }

    // async function update_item(path, update) {
    //     const res = await axios.post(`${USER_SERVER}/update_item`, {token, path, update});
    //     console.log('update_item--------->', res);
    // }

    async function addProjectList(projectName) {
        const res = await axios.post(`${USER_SERVER}/insert_project`, {token, name:projectName});
        console.log('insert_project--------->', res);
        //setProjectList([...ProjectList, projectName]);
        await get_project();
    }


    async function set_item_to_curr_project(item_path) {
        const dataToCommit = {token, path:item_path, update:{project_id:CurrProjectId}};
        const res = await axios.post(`${USER_SERVER}/update_item`, dataToCommit);
        console.log('update_item--------->', res);
        await get_history();
    }

        
    return (
        <div>
            <Row>
                <Col span={4}>
                    <LandingPageLeft ProjectList={ProjectList} 
                                    addProjectList={addProjectList} 
                                    CurrProjectId={CurrProjectId} 
                                    setCurrProjectId={setCurrProjectId}
                                    set_item_to_curr_project={set_item_to_curr_project}/>
                </Col>
                <Col span={20}>
                    <LandingPageRight CurrProjectId={CurrProjectId}
                                    HistoryList={HistoryList}
                                    update_item={update_item}
                                    onClickDelete={onClickDelete}
                                    onClickCreateNext={onClickCreateNext}
                                    get_history={get_history}
                                    />
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(LandingPage)
